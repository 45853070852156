<template>
  <div class="container">
    <div class="row text-start">
      <h1>Acerca de</h1>
      <p>
       <mark>México, date cuenta</mark> es una herramienta de <mark>Serendipia</mark> para hacer públicos
        los datos sobre el gasto ejercido por los gobiernos estatales y
        municipales para atender la Alerta de Violencia de Género contra las
        Mujeres (AVGM). La Alerta de Violencia de Género contra las Mujeres
        (AVGM) es un conjunto de acciones gubernamentales de emergencia
        encaminadas a enfrentar y erradicar la violencia de género en un
        territorio determinado. Según
        <a
          href="https://www.gob.mx/cms/uploads/attachment/file/209278/Ley_General_de_Acceso_de_las_Mujeres_a_una_Vida_Libre_de_Violencia.pdf"
          >la Ley General de Acceso de las Mujeres a una Vida Libre de
          Violencia</a
        >, la AVGM tiene como objetivos garantizar la seguridad de niñas y
        mujeres, así como eliminar las desigualdades que atentan contra sus
        derechos humanos. Actualmente, la AVGM se encuentra activa en 19 de los
        32 estados del país. Estos son Baja California, Campeche, Chiapas,
        Colima, Durango, Estado de México, Guerrero, Jalisco, Michoacán,
        Morelos, Nayarit, Nuevo León, Oaxaca, Puebla, Quintana Roo, San Luis
        Potosí, Sinaloa, Veracruz y Zacatecas. En cada Declaratoria de AVGM se
        establecen cuatro categorías de incidencia: prevención, seguridad,
        justicia y visibilización. Dichas medidas están encaminadas a garantizar
        el derecho de las mujeres a vivir una vida libre de violencia y no son
        excluyentes. Es decir, deben implementarse junto con otras propuestas
        que surjan a raíz de las necesidades particulares que se presenten en
        cada entidad. Con la intención de conocer cuánto se gasta en Alerta de
        Género en los 19 estados en que se ha declarado hasta enero de 2022,
        Serendipia dirigió 892 solicitudes de acceso a la información pública a
        través de la
        <a
          href="https://buscador.plataformadetransparencia.org.mx/buscadornacional"
          >Plataforma Nacional de Transparencia</a
        >
        (PNT). De ellas, cuatro fueron a dependencias federales: el
        <a href="https://www.gob.mx/inmujeres"
          >Instituto Nacional de las Mujeres</a
        >
        (INMUJERES), la
        <a href="https://www.gob.mx/conavim"
          >Comisión Nacional para Prevenir y Erradicar la Violencia Contra las
          Mujeres</a
        >
        (CONAVIM), la
        <a href="https://www.gob.mx/fgr">Fiscalía General de la República</a>
        (FGR) y la
        <a href="https://www.cndh.org.mx/"
          >Comisión Nacional de los Derechos Humanos</a
        >
        (CNDH). Las demás se enviaron a los 19 gobiernos y sus organismos
        estatales, así como a los municipios de cada entidad en que actualmente
        se encuentra activa la Alerta. En estas solicitudes se requirió una
        relación de las erogaciones realizadas para atender la AVGM desde que
        existiera registro. También se pidió la copia en versión pública de
        todos los documentos (facturas, contratos, órdenes de compra) que
        comprobaran dichos gastos. A partir de las respuestas obtenidas,
        Serendipia vació los datos y los categorizó con base en las cuatro
        medidas contempladas en las Declaratorias de Alerta: prevención,
        seguridad, justicia y visibilización de la violencia. También contempló
        la opción “sin categoría” para aquellos gastos no enfocados en atender
        la violencia de género o que no estén especificados por las
        dependencias. Una vez hecho esto, la información fue analizada para
        conocer el monto destinado a cada categoría. La primera entrega de este
        proyecto comprende el reporte de gastos realizados en dos entidades:
        Chiapas y Durango. Debido al gran volumen de datos obtenidos que es
        necesario sistematizar y categorizar, este es un proyecto que <mark>Serendipia</mark>
        desarrollará a lo largo de todo el año y cuya actualización será de
        forma mensual.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    change_section: Function,
  },
  setup(props) {
    props.change_section("Acerca de");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  name: "AcercaDe",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
mark {
  font-weight: bolder;
  background-color: transparent;
}
</style>