<template>
  <div class="container">
    <div class="row text-start">
      <h1>Metodología:</h1>
      <p>
        <mark> Serendipia </mark> dirigió 892 solicitudes de acceso a la
        información pública a todas las entidades en donde está activa la AVGM
        hasta enero de 2022: Baja California, Campeche, Chiapas, Colima,
        Durango, Estado de México, Guerrero, Jalisco, Michoacán, Morelos,
        Nayarit, Nuevo León, Oaxaca, Puebla, Quintana Roo, San Luis Potosí,
        Sinaloa, Veracruz y Zacatecas. También fueron dirigidas solicitudes a
        las siguientes dependencias federales: el Instituto Nacional de las
        Mujeres (INMUJERES), la Comisión Nacional para Prevenir y Erradicar la
        Violencia Contra las Mujeres (CONAVIM), la Fiscalía General de la
        República (FGR) y la Comisión Nacional de los Derechos Humanos (CNDH).
        Las solicitudes fueron dirigidas tanto a los gobiernos estatales como a
        las dependencias estatales encargadas de implementar la AVGM y a los
        municipios dentro de cada entidad en donde está activa la Alerta de
        Género.
        <br />
        Los datos de incidencia delictiva son descargados del sitio web del
        <a
          href="https://www.gob.mx/sesnsp/acciones-y-programas/incidencia-delictiva-87005"
          >Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública
          (SESNSP)</a
        >
        y de los
        <a
          href="https://drive.google.com/file/d/1NBXVxuDczwaL2XVf4l0br8HL0JlBvbTz/view"
          >reportes sobre violencia contra las mujeres</a
        >
        del mismo órgano. Los datos para 2021 son los publicados por el SESNSP
        con el cálculo de tasa. Para los años anteriores (2015-2020) Serendipia
        calculó la tasa por cada 100 mil mujeres usando la siguiente fórmula:
        (Número de delitos contra las mujeres / Total de mujeres) * 100,000 =
        tasa de delito x 100,000 habitantes.
      </p>
    </div>
    <div class="row">
      <h1>Descarga los datos</h1>
      <form class="d-flex align-items-center mb-3">
        <h3 class="me-3 mb-0">Entidad seleccionada:</h3>
        <select
          name="select"
          class="select body1"
          id="dowloadSelect"
          v-model="entidad"
        ></select>
      </form>
      <div>
        <table>
          <thead>
            <tr>
              <th><h3>Entidad</h3></th>
              <th><h3>Gastos de la AVGM</h3></th>
              <th><h3>Incidencia delictiva</h3></th>
              <th><h3>Contratos y facturas</h3></th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(descargable, i) in descargables"
              :key="descargable.Estado"
            >
              <tr
                v-if="descargable.Estado == entidad || entidad == 'Todas'"
                :class="{ par: i % 2 == 0 }"
              >
                <td class="descargables">
                  <p>{{ descargable.Estado }}</p>
                </td>
                <td class="descargables">
                  <a
                    v-if="descargable.gastosAVGM"
                    :href="'/descargables/gastosAVGM/' + descargable.gastosAVGM"
                    download
                    >Descargar
                    <img
                      src="../assets/download-icon.svg"
                      alt="Descargar datos incidencia delictiva"
                  /></a>
                </td>
                <td class="descargables">
                  <a
                    v-if="descargable.incidenciaDelictiva"
                    :href="
                      '/descargables/incidenciaDelictiva/' +
                      descargable.incidenciaDelictiva
                    "
                    download
                    >Descargar
                    <img
                      src="../assets/download-icon.svg"
                      alt="Descargar datos incidencia delictiva"
                  /></a>
                </td>
                <td class="descargables">
                  <a
                    v-if="descargable.contratosFacturas"
                    :href="descargable.contratosFacturas"
                    target="_blank"
                    >Descargar
                    <img
                      src="../assets/download-icon.svg"
                      alt="Descargar datos incidencia delictiva"
                  /></a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import des from "../descargable.json";
import * as d3 from "d3v4";
export default {
  props: {
    change_section: Function,
  },
  setup(props) {
    props.change_section("Metodología y descarga de datos");
    const descargables = reactive(des);
    const entidad = ref("Todas");
    return {
      descargables,
      entidad,
    };
  },
  mounted() {
    let estados = ["Todas", ...new Set(des.map((d) => d.Estado))];
    let dowloadSelect = d3
      .select("#dowloadSelect")
      .selectAll("option")
      .data(estados);
    dowloadSelect
      .enter()
      .append("option")
      .attr("value", (d) => d)
      .text((d) => d)
      .attr("class", "body1");
  },
  name: "DescargaDatos",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
mark {
  font-weight: bolder;
  background-color: transparent;
}
h3 {
  font-size: 16px;
}
.par {
  background-color: #f4f4f4;
}
.descargables {
  margin-bottom: 10px;
}
.descargables a {
  border-radius: 25px;
  background-color: #6f46bc;
  color: #ffffff;
  text-decoration: none;
  padding: 8px;
  font-size: 10px;
}
.descargables p {
  margin: 0;
  font-size: 16px;
  font-weight: bolder;
}
.descargables a img {
  width: 15px;
}
thead tr th {
  color: #6f46bc;
}
tbody tr {
  height: 60px;
}
@media (min-width: 768px) {
  .descargables a {
    font-size: 16px;
  }
  .descargables a img {
    width: 15px;
  }
  .descargables p {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  h3 {
    font-size: 24px;
  }
}
</style>