<template>
  <div class="container">
    <div class="row text-start">
      <h1>Feminicidios</h1>
      <div class="row">
        <div class="col-4"><h2>Entidad</h2></div>
        <div class="col-4"><h2>Año</h2></div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <select
            name="estados_select_name"
            id="estados_select_incidencia"
            class="select body2"
          ></select>
        </div>
        <div class="col-4">
          <select
            name="year_select_name"
            id="year_select_incidencia"
            class="select body2"
          ></select>
        </div>
      </div>
      <div class="containerTable">
        <table id="gridIncidenciaAvgm">
          <thead>
            <tr>
              <th><h2>Estado</h2></th>
              <th><h2>Año</h2></th>
              <th><h2>Delito</h2></th>
              <th><h2>Total</h2></th>
              <th><h2>Tasa por cada 100,000</h2></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3v4";
var formatNumber = d3.format(",.2f");
export default {
  props: {
    change_section: Function,
  },
  setup(props) {
    props.change_section("Feminicidios");
  },
  async mounted() {
    // Actualiza dropdowns [Estado, Año, Delito] en tasa de incidencia delictica
    await d3.csv("/incidencia_tasa_tabla.csv", (err, data) => {
      data = data.filter(
        (d) => d.Delito == "Violencia familiar (presuntas víctimas)"
      );
      // añade estados a dropdown
      (function () {
        d3.select("#estados_select_incidencia").selectAll("option").remove();
        let estados = ["Todas", ...new Set(data.map((d) => d.Estado))];
        let estadosSelect = d3
          .select("#estados_select_incidencia")
          .selectAll("option")
          .data(estados);
        estadosSelect
          .enter()
          .append("option")
          .attr("value", (d) => d)
          .text((d) => d)
          .attr("class", "body2");
        d3.select("#year_select_incidencia").selectAll("option").remove();
        let year = [...new Set(data.map((d) => d.Año)), "Todos"].reverse();
        let yearSelect = d3
          .select("#year_select_incidencia")
          .selectAll("option")
          .data(year);
        yearSelect
          .enter()
          .append("option")
          .attr("value", (d) => d)
          .text((d) => d)
          .attr("class", "body2");
      })();
      // añade Años a dropdown
      function selectData() {
        let estado = d3.select("#estados_select_incidencia").property("value");
        let yearSelected = d3
          .select("#year_select_incidencia")
          .property("value");
        let dataSd = [...data];
        if (estado != "Todas") {
          dataSd = data.filter((d) => d.Estado == estado);
        }

        if (yearSelected != "Todos") {
          dataSd = dataSd.filter((d) => d.Año == yearSelected);
        }
        d3.select("#gridIncidenciaAvgm")
          .select("tbody")
          .selectAll("tr")
          .remove();
        tableIncidenciasAvgm(dataSd);
      }
      // Actualiza tabla incidencia delictiva y los años al cambiar de estado
      d3.select("#estados_select_incidencia").on("change", () => selectData());
      d3.select("#year_select_incidencia").on("change", () => selectData());
      selectData(data);
    });
    // funcion para crear tabla
    function tableIncidenciasAvgm(data) {
      let tbodyGastos = d3
        .select("#gridIncidenciaAvgm")
        .select("tbody")
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .attr("class", (d, i) => {
          if (i % 2 == 0) {
            return "tr_par";
          }
          return "tr_inpar";
        });
      tbodyGastos.append("td").text((d) => d.Estado);
      tbodyGastos.append("td").text((d) => d.Año);
      tbodyGastos.append("td").text((d) => d.Delito);
      tbodyGastos.append("td").text((d) => d.Total);
      tbodyGastos
        .append("td")
        .text((d) => formatNumber(d["Tasa por cada 100 mil mujeres"]));
    }
  },
  name: "Feminicidios",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>